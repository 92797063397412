@import "variables";
@import "mixins";

.nav-container {
  position: absolute;
  width: 100%;
  background: transparent;
  color: white;
  font-size: 14px;
}

.hero {
  height: 460px;
  width: 100%;
  display: flex;
  background-blend-mode: multiply;
  background-image: linear-gradient(
      to bottom,
      rgba(49, 43, 36, 0.75),
      rgba(54, 49, 39, 0.15)
    ),
    url("/assets/img/index-hero.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.hero-content {
  display: grid;
  flex: 1;
  grid-template-rows: 200px 48px auto;
}

.search-container {
  background-color: #f5f5f5;
  height: 120px;
  align-self: center;
  width: 100%;
  max-width: 820px;
  padding: 0 16px;
}

#search-top {
  display: flex;
  flex-direction: row;
}

.search {
  transform: translateY(-50%);
  border-radius: 8px;
  box-shadow: 0 5px 50px -15px rgba(102, 94, 56, 0.5);
  background-color: transparent;
  overflow: hidden;
  width: 100%;
}

#search-main {
  display: flex;
}

#search-input {
  width: 10px;
  border: none;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
}

.search-input {
  padding-left: 20px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 2px;
}

.search-input::placeholder {
  color: #9e9e9e;
}

.tagline {
  grid-row: 2;

  text-shadow: 0 2px 5px #212121;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.5;
  &:lang(zh-TW) {
    letter-spacing: 2px;
  }
  text-align: center;
  color: #ffffff;
  @include md {
    font-size: 42px;
    &:lang(zh-TW) {
      letter-spacing: 10px;
    }
  }
}

.ll-section {
  background-color: #f5f5f5;
  width: 100%;
}

.ll-steps-h {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 4px;
  color: #3c2d1a;
  margin-top: 0;
  margin-bottom: 10px;
}

.ll-steps-p {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 2px;
  color: #616161;
}

.bg-white-8 {
  background-color: rgba(255, 255, 255, 0.8);
}

#steps-wrapper {
  margin: 0 auto;
  @include lg {
    max-width: 820px;
  }
}

#step-heading {
  @include lg {
    padding-top: 80px;
  }
}

#lg-bg {
  position: absolute;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.8);
  min-height: 100%;
  left: 50px;
  right: 0;
}

#circle-1 {
  position: absolute;
  left: 0;
  height: 524px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  @include lg {
    display: block;
  }
}

.step-container {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  background: rgba(255, 255, 255, 0.8);
  @include lg {
    background: none;
  }
}

.step-icon {
  position: relative;
}

.step-img {
  width: 80px;
}

.step-text {
  margin: 0px 25px;
  display: flex;
  flex-direction: column;
  padding-left: 80px;
}

.step-hp {
  min-height: 127px;
  position: relative;
}

.ll-step-side {
  position: absolute;
  left: -80px;
  top: 5px;
  min-height: 100%;
}

.ll-step-line {
  flex: 1;
  min-width: 1px;
  min-height: 100%;
  background: $normal;
  position: absolute;
  left: 11.5px;
  top: 12px;
}

.text-small {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.sec-pad {
  padding-top: 140px;
}

.lr-pad {
  padding-left: 25px;
  padding-right: 25px;
}

.place-slide-wrapper {
  @include md {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 60px;
  }
  .glide__arrow--left {
    left: -16px;
    transform: translate(-100%, -50%);
  }
  .glide__arrow--right {
    left: unset;
    right: -16px;
    transform: translate(100%, -50%);
  }
}

.place-card {
  display: block;
  width: 100%;
  height: 210px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 10px 0 rgba(102, 94, 56, 0.5);
  background-image: url("/assets/img/places/676fd1533451a988fc3bb480ed16444f.jpg"),
    linear-gradient(to top, rgba(49, 43, 36, 0.75), rgba(54, 49, 39, 0.15));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
}

.place-card:not(:first-child) {
  margin-left: 15px;
}

.place-card-content {
  position: relative;
  height: 100%;
  background-blend-mode: multiply;
}

.place-card-place {
  position: absolute;
  font-weight: bold;
  line-height: 1.5;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  bottom: 38px;
  text-align: center;
  left: 0.5em;
  right: 0.5em;
  letter-spacing: 4px;
}

.place-card-city {
  position: absolute;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
  left: 0.5em;
  right: 0.5em;
  top: 172px;
  color: #fff;
}

.glide__bullets.ll {
  position: static;
  margin-left: auto;
  margin-right: auto;
  transform: none;
}

.ll .glide__bullet {
  background-color: #e0e0e0;
  border: none;
}

.ll .glide__bullet.glide__bullet--active {
  background-color: #fdc300;
}

.ll .glide__bullet:hover,
.ll .glide__bullet:focus {
  background-color: #fdc300;
  border: none;
}

.price-box {
  display: flex;
  flex-direction: column;
  height: 350px;
  width: 240px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px 0;
}

.price-box-wrapper + .price-box-wrapper {
  margin-top: 20px;
  @include md {
    margin-top: 0;
  }
}

.price-box > img {
  width: 110px;
  height: 110px;
}

.price-line {
  margin-top: 11px;
  display: flex;
  align-items: baseline;
  flex-grow: 1;
}

.lr-mg-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.lr-mg-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.currency {
  margin-right: 10px;
}

.price-title {
  flex: 1;
}

.price {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 2px;
  color: $green;
}

.perday {
  text-align: right;
}

#supporters {
  padding-top: 40px;
  border-top: 1px solid #3c2d1a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
  margin-right: 25px;
  width: calc(100% - 50px);
  margin-bottom: 140px;
  @include lg {
    flex-direction: row;
    max-width: 620px;
  }

  & > img + img {
    margin-top: 20px;
    @include lg {
      margin-top: 0;
      margin-left: 32px;
    }
  }
}

#reviews {
  padding: 60px 25px;
  background-color: $grey;
}

#vendor {
  padding: 60px 90px;
  background-image: url("/assets/img/vendor-bg.jpg"),
    linear-gradient(to top, rgba(49, 43, 36, 0.6), rgba(54, 49, 39, 0.15));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  align-self: stretch;
  @include lg {
    padding-top: 108px;
    padding-bottom: 108px;
  }
}

#price-heading {
  padding-bottom: 33px;
  &.v {
    text-align: center;
    @include md {
      text-align: left;
    }
  }
}

.glide {
  position: relative;
}

.review-slide .glide__slide {
  padding: 0 50px;
  @include lg {
    padding: 0 100px;
  }
}

.glide__arrow--ll {
  border: none;
  color: $normal;
  height: 44px;
  width: 44px;
  font-size: 22px;
  box-shadow: none;
  transition: 0.15s background-color;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  display: none;
  @include lg {
    display: block;
  }
}

#search-controls {
  height: 0;
  width: 100%;
  border-top: 1px solid #c8c8c8;
  overflow: hidden;
  transition: height 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  @include lg {
    order: 20;
  }
  &.active {
    height: 54px * 2;
    @include md {
      height: 54px;
    }
  }
  & > .columns > .column:not(:last-child) {
    border-bottom: 1px solid #c8c8c8;
    @include md {
      border-bottom: none;
      border-right: 1px solid #c8c8c8;
    }
  }
}

.h54 {
  height: 54px;
}

.navbar-item,
.navbar-link {
  color: $white;
  text-decoration: none;
}

a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  color: $light;
}

.navbar-burger {
  color: $white;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: white;
}

a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  color: $golder;
}

.navbar {
  box-shadow: none;
}

.why-1 {
  width: 180px;
  height: 180px;
  object-fit: contain;
}

.why-h {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 20px;
  margin-bottom: 20px;
}

.touch-26 {
  padding-left: 26px;
  padding-right: 26px;
  @include md {
    padding-left: unset;
    padding-right: unset;
  }
}

.faq-wrapper {
  border-radius: 16px;
  overflow: hidden;
}

.faq-block {
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  & + & {
    border-top: 1px solid $grey2;
  }
}

.faq-block-head {
  padding-top: 23px;
  padding-bottom: 23px;
  padding-right: 2em;
  font-size: 16px;
  position: relative;

  &::after {
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    display: block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    content: "\f078";
    font-weight: 900;
    position: absolute;
    right: 0;
    top: 50%;
    transition: 250ms ease-out;
    transform: translateY(-50%);
    .collapsed > & {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.faq-block-body {
  padding-top: 20px;
  padding-bottom: 30px;
  font-size: 14px;
}

.v-tagline {
  text-shadow: 0 2px 5px #212121;
  line-height: 50px;
  font-size: 42px;
  font-weight: bold;
  color: #fff;
  display: block;
  padding-bottom: 20px;
  border-bottom: 1px solid $golder;
}

.v-subtag  {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  color: #fff;
  margin-top: 10px;
}

@include cjk {
  .v-tagline {
    letter-spacing: 3px;
  }
  .v-subtag {
    letter-spacing: 4px;
  }
}

.hero-content-v {
  width: 100%;
  padding-top: 90px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @include md {
    padding-top: 163px;
    padding-left: 10vw;
    padding-right: 10vw;
    flex-direction: row;
  }
}

.hero-form {
  border-radius: 8px;
  overflow: hidden;
  width: 280px;
  margin-top: 50px;
  @include md {
    margin-top: unset;
    margin-left: 50px;
  }
}

.hero-main {
  flex-grow: 1;
}

.hero-input {
  background: #fff;
  border: none;
  border-top: 1px solid $grey2;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}